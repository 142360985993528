@use 'sass:color';
@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: flex;
  align-items: center;
  column-gap: pxToRem(4);
  transition: opacity 300ms ease-in-out;

  &.unavailable {
    opacity: 0;
  }
}

.imageWrapper {
  border-radius: 50%;
  background: $color-sentimental-caution-100;
  font-size: pxToRem(16);
  width: 1em;
  height: 1em;
  margin-top: -0.1em;

  :global {
    .icon {
      display: block;
      font-size: inherit;
    }
  }
}

.countdown {
  @include fontSize(14, 18);
  @include fontNumeric();
  letter-spacing: pxToRem(0.2);
}

@include mdp {
  .imageWrapper {
    font-size: pxToRem(20);
  }
  .countdown {
    @include fontSize(17, 24);
  }
}
