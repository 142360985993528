@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  --border-radius: #{pxToRem(2)};
  display: block;
  width: 100%;
  height: pxToRem(4);
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
}
.progress {
  display: block;
  width: 100%;
  height: pxToRem(4);
  border-radius: var(--border-radius);

  &[value] {
    appearance: none;

    &::-webkit-progress-bar {
      background: var(--for-neu-tie3);
      border-radius: var(--border-radius);
    }

    &::-webkit-progress-value {
      background: var(--for-suc-bas);
      border-radius: 0 pxToRem(4) pxToRem(4) 0;
      transition: all ease-in-out 300ms;
    }
  }
}
